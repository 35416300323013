import React from "react";
import UsuarioCabecera from "../Componentes/Componentes-secundarios/Usuario-cabecera";
import AuthService from "../Componentes/Componentes-login/service/auth.service";

const MiPerfil = (props) => {
  const currentUser = AuthService.getCurrentUser();
  
  return (
    <div className="conteiner contenedor-perfil">
      <div id="cabecera" className="header">
        <div className="div-icon-header">
          <i className="material-icons md-40">person</i>
        </div>
        <div className="div-h2">
          <hr className="v" />
          <h2 className="h2">Perfil</h2>
        </div>
        <UsuarioCabecera />
      </div>
      <div id="contenido" className="contenido contenido-perfil section">
        <div className="contenedor-vista">
          <div className="cont-perfil">
            <div className="div-morado">
              <div className="div-avatar">
                <span className="material-icons md-51">account_circle</span>
              </div>
              <div className="div-nombre">
                {/*Aqui va el área de la persona y abajo el nombre del usuario */}
                <h5 className="h5-area">{currentUser.user.firstname} {currentUser.user.lastname}</h5>
               
              </div>
            </div>
            <div className="div-info">
              <div className="correo-time correo">
                <span className="material-icons md-25">email</span>
                <p className="p-correo-time">{currentUser.user.email}</p>
              </div>
              <div className="correo-time time">
                <span className="material-icons md-25">restore</span>
                <p className="p-correo-time">15:30</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiPerfil;
