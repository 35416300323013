// //REACT_APP_API_BASE_URL=$API_BASE_URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_LOCAL_URL = process.env.REACT_APP_API_LOCAL_URL;
export const ADMIN_TOKEN = process.env.REACT_APP_ADMIN_API_KEY_TOKEN;
export const USER_TOKEN = process.env.REACT_APP_USER_API_KEY_TOKEN;

// const config = {
//     dev: process.env.API_BASE_URL !== 'production',
//     port: process.env.API_LOCAL_URL || 3000,
    
// };

// export default {config};