import React from "react";
import SearchBar from "./searchBar";

const Tabla = (props) => {
  
  return (
    <div className="content-list">
      <SearchBar/>
    </div>
  );
};

export default Tabla;
