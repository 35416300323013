import React from 'react';

const BtnLogin = (props) => {
  
  return (
    <div>
      <button className='btn-login btn' >
        Login
      </button>
    </div>
  );
};

export default BtnLogin;
